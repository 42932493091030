import React from "react"
import { graphql, Link } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"
import moment from 'moment'
import _ from 'lodash'

import Layout from "../components/layout"
import SEO from "../components/seo"
import SpotlightArticle from "../components/SpotlightArticle"
import birthdays from "../media/img/news/Birthdays.jpg"
import newwork from "../media/img/news/new-work.jpg"
import promotions from "../media/img/news/promotions.jpg"
import workanni from "../media/img/news/pasteve.jpg"
import newsSpotlight from "../media/img/news/news-spotlight.jpg"

import galleryThumb from "../media/img/news/gallerythumb.png"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Wiedenism from "../components/Wiedenism"

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: true,
  responsive: [{
      breakpoint: 768,
      settings: {
          slidesToShow: 1,
          slidesToScroll: 1
      }
  }
  ]
}

export default function WhatsHappening(props) {
  let allEmployees = []
  props.data.allIntranetEmployees.edges.map(employee => {
    allEmployees.push({
      "name": employee.node.name,
      "dob": employee.node.dob,
      "doj": employee.node.doj,
      "office": employee.node.office,
      "image": employee.node.bday_image,
      "annimage": employee.node.anniversary_image,
    })
    return allEmployees
  })
  // console.log(allEmployees)
  let upcomingBirthdays = [], upcomingWorkAnniversaries = [], pastEvents = []
  let hasBirthdayToday = false, hasAnniversaryToday = false
  function daysUntil(date) {
    let birthday = moment(date)
    let today = moment().format("YYYY-MM-DD")
    let age = moment(today).diff(birthday, 'years')
    moment(age).format("YYYY-MM-DD")
    
    let nextBirthday = moment(birthday).add(age, 'years')
    moment(nextBirthday).format("YYYY-MM-DD")
    
    if (nextBirthday.isSame(today)) {
      hasBirthdayToday = true
      return ({
        "days":0,
      });
    } else {
      nextBirthday = moment(birthday).add(age + 1, 'years');
      return ({
        "days":nextBirthday.diff(today, 'days'),
      })
    }
  }

  function daysUntilAnniversary(date) {
    let anniversary = moment(date)
    let today = moment().format("YYYY-MM-DD")
    let age = moment(today).diff(anniversary, 'years')
    moment(age).format("YYYY-MM-DD")
    
    let nextAnniversary = moment(anniversary).add(age, 'years')
    moment(nextAnniversary).format("YYYY-MM-DD")
    
    if (nextAnniversary.isSame(today)) {
      hasAnniversaryToday = true
      return ({
        "days":0,
        "years":age-1,
      });
    } else {
      nextAnniversary = moment(anniversary).add(age + 1, 'years');
      return ({
        "days":nextAnniversary.diff(today, 'days'),
        "years":age,
      })
    }
  }
  

  allEmployees.map(employee => {
    //check birthday and create new array
    let checkBirthday = daysUntil(employee.dob)
    if(hasBirthdayToday && checkBirthday.days === 0){
      upcomingBirthdays.push({
        "name": employee.name,
        "dob": employee.dob,
        "doj": employee.doj,
        "office": employee.office,
        "image": employee.image,
        "days_remaining": checkBirthday.days
      })
    }
    else if(!hasBirthdayToday && (checkBirthday.days > 0) && (checkBirthday.days <= 30)){
      upcomingBirthdays.push({
        "name": employee.name,
        "dob": employee.dob,
        "doj": employee.doj,
        "office": employee.office,
        "image": employee.image,
        "days_remaining": checkBirthday.days
      })
    }
    else if(!hasBirthdayToday && (checkBirthday.days >= (365-30))){
      pastEvents.push({
        "name": employee.name,
        "type":"birt",
        "dob": employee.dob,
        "doj": employee.doj,
        "office": employee.office,
        "image": employee.image,
        "days_remaining": checkBirthday.days
      })
    }
    //check Anniversary and create new array
    let checkAnniversary = daysUntilAnniversary(employee.doj)
    if(hasAnniversaryToday && checkAnniversary.days === 0){
      upcomingWorkAnniversaries.push({
        "name": employee.name,
        "dob": employee.dob,
        "doj": employee.doj,
        "office": employee.office,
        "image": employee.annimage,
        "days_remaining": checkAnniversary.days,
        "total_years": checkAnniversary.years
      })
    }
    else if(!hasAnniversaryToday && (checkAnniversary.days > 0) && (checkAnniversary.days <= 30)){
      upcomingWorkAnniversaries.push({
        "name": employee.name,
        "dob": employee.dob,
        "doj": employee.doj,
        "office": employee.office,
        "image": employee.annimage,
        "days_remaining": checkAnniversary.days,
        "total_years": checkAnniversary.years
      })
    }
    else if(!hasAnniversaryToday && checkAnniversary.years > 0 && (checkAnniversary.days >= (365-30))){
      pastEvents.push({
        "name": employee.name,
        "type":"anni",
        "dob": employee.dob,
        "doj": employee.doj,
        "office": employee.office,
        "image": employee.annimage,
        "days_remaining": checkAnniversary.days,
        "total_years": checkAnniversary.years
      })
    }
    return true
  })
  let sortByLatestBirthday = _.sortBy(upcomingBirthdays, 'days_remaining')
  let sortByLatestAnniversary = _.sortBy(upcomingWorkAnniversaries, 'days_remaining')
  return (
    <Layout pageInfo={{ pageName: "News" }}>
    <SEO title="News" />
    <SpotlightArticle tags={`What's Happening`} title={`You heard it here first! Your coworkers’ birthdays, work anniversaries, new work, awards or account wins - this is the place where you’ll know about the latest and greatest at Wieden+Kennedy India. 
`} subHead={``} bgImg={newsSpotlight} borderColor="#1514f4"/>
    <section>
      <Container>
        <Row>
          <Col sm={4}>
            <div className="carousel-thumb">
              <img src={birthdays} alt="" />
            </div>
          </Col>
          <Col sm={8}>
            <h3>Birthdays</h3>
            {hasBirthdayToday && (<h4 className="mb-0 font-weight-normal">Today!</h4>)}
            {!hasBirthdayToday && (<h4 className="mb-0 font-weight-normal">Upcoming!</h4>)}
            {hasBirthdayToday && (<small>{moment().format('MMMM Do YYYY')}</small>)}
            {!hasBirthdayToday && (<small>In next 30 days</small>)}
            
            <div className="carousel-wrap my-3">
              <Slider {...settings}>
                {sortByLatestBirthday.map((birthday, index) => {
                  return(
                    <div className="carousel--content" key={index}>
                      <img src={birthday.image === `` ? galleryThumb : birthday.image } alt=""></img>
                      <p>{birthday.name} - <span class="uppercase">{birthday.office}</span> - {moment(birthday.dob).format('MMMM D')}</p>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </Col>
          </Row>
          
          <div className="sec--divider--border"></div>
          
          <Row>
            <Col sm={4}>
              <div className="carousel-thumb">
                <img src={workanni} alt="" />
              </div>
            </Col>
            <Col sm={8}>
              <h3>Work Anniversaries</h3>
              {hasAnniversaryToday && (<h4 className="mb-0 font-weight-normal">Today!</h4>)}
              {!hasAnniversaryToday && (<h4 className="mb-0 font-weight-normal">Upcoming!</h4>)}
              {hasAnniversaryToday && (<small>{moment().format('MMMM Do YYYY')}</small>)}
              {!hasAnniversaryToday && (<small>In next 30 days</small>)}
              
              <div className="carousel-wrap my-3">
                <Slider {...settings}>
                  {sortByLatestAnniversary.map((anniversary, index) => {
                    if(anniversary.total_years > 0){
                      return(
                        <div className="carousel--content" key={index}>
                          <img src={anniversary.image === `` ? galleryThumb : anniversary.image } alt=""></img>
                          {hasAnniversaryToday &&
                            <p>{anniversary.name} - {`Completed ${anniversary.total_years === 0 ? 1: anniversary.total_years} ${anniversary.total_years > 1 ? `years`: `year`}`}</p>
                          }
                          {!hasAnniversaryToday &&
                            <p>{anniversary.name} - {`Completing ${anniversary.total_years+1} ${anniversary.total_years > 1 ? `years`: `year`}`}</p>
                          }
                        </div>
                      )
                    }
                  })}
                </Slider>
              </div>
            </Col>
          </Row>

        {/* <div className="sec--divider--border"></div>

        <Row>
            <Col sm={4}>
              <div className="carousel-thumb">
                <img src={workanni} alt="" />
              </div>
            </Col>
            <Col sm={8}>
              <h3>Past Events</h3>
              {!hasAnniversaryToday && (<small>In Past 30 days</small>)}
              
              <div className="carousel-wrap my-3">
                <Slider {...settings}>
                  {pastEvents.map((event, index) => {
                    return(
                      <div className="carousel--content" key={index}>
                        <img src={event.image === `` ? galleryThumb : event.image } alt=""></img>
                        {event.type === `anni` &&(<><h4 className="h6 mt-2">Work Anniversary</h4><p>{event.name} - {`Completed ${event.total_years} ${event.total_years > 1 ? `years`: `year`}`}</p></>)}
                        {event.type === `birt` &&(<><h4 className="h6 mt-2">Birthday</h4><p>{event.name} - {moment(event.dob).format('MMMM D')}</p></>)}
                      </div>
                    )
                  })}
                </Slider>
              </div>
            </Col>
          </Row> */}

        {/* <div className="sec--divider--border"></div>
          
        <Row>
          <Col sm={4}>
            <div className="carousel-thumb">
              <img src={newwork} alt="" />
            </div>
          </Col>
          <Col sm={8}>
            <h3>New work</h3>
            <div className="carousel-wrap my-3">
              <Slider {...settings}>
                {props.data.work.edges.map(work => (
                  <div className="carousel--content">
                    <img src={work.node.image !== `` ? work.node.image : galleryThumb} alt=""></img>
                    <p>{work.node.title}</p>
                    {work.node.type === "internal" && 
                      <small><Link to={`/work/${work.node.id__normalized}`}>Know More</Link></small>
                    }
                    {work.node.type === "external" && 
                      <small><a href={work.node.link} rel="noreferrer" target="_blank">Know More</a></small>
                    }
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
        </Row> */}

        <div className="sec--divider--border"></div>
          
          <Row>
          <Col sm={4}>
            <div className="carousel-thumb">
              <img src={promotions} alt="" />
            </div>
          </Col>
          <Col sm={8}>
            <h3>Joining, Exit, Promotions</h3>            
            <div className="carousel-wrap my-3">
              <Slider {...settings}>
                {props.data.lifeCycles.edges.map(lifecycle => (
                  <div className="carousel--content" id={lifecycle.node.id}>
                    <img src={lifecycle.node.emp_image !== `` ? lifecycle.node.emp_image : galleryThumb} alt="" />
                    <p className="mb-0 capitalcase">{lifecycle.node.emp_name} | {lifecycle.node.emp_office} | {lifecycle.node.category} </p>
                    <small>{lifecycle.node.title}</small>
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
        </Row>

      </Container>
      <Wiedenism />
        {/* <div className="home__relative__links">
            <Container>
                <Row className="d-flex">
                    <Col>
                        <BorderLayout color={`#A8233D`}>
                            <div className="links__Wrap">
                                <RelatedArticleSingle title={`Who We Are`} slug={`/who-we-are`} />
                            </div>
                        </BorderLayout>
                    </Col>
                    <Col>
                        <BorderLayout color={`#30A2DE`}>
                            <div className="links__Wrap">
                                <RelatedArticleSingle title={`The Cheat Sheet`} slug={`/the-cheat-sheet`} />
                            </div>
                        </BorderLayout>
                    </Col>
                    <Col>
                        <BorderLayout color={`#FFB100`}>
                            <div className="links__Wrap">
                                <RelatedArticleSingle title={`Learn & Grow`} slug={`/learn-and-grow`} />
                            </div>
                        </BorderLayout>
                    </Col>
                    <Col>
                        <BorderLayout color={`#8163E7`}>
                            <div className="links__Wrap">
                                <RelatedArticleSingle title={`Other Essentials`} slug={`/other-essentials`} />
                            </div>
                        </BorderLayout>
                    </Col> 
                </Row>
            </Container>
        </div> */}
    </section>
  </Layout>
  )
}

export const query = graphql`
    query Employees {
      allIntranetEmployees {
        edges {
          node {
            id
            name
            image
            doj
            dob
            office
            email
            bday_image
            bday_copy
            anniversary_copy
            anniversary_image
          }
        }
      }
      lifeCycles: allIntranetLifeCycles {
        edges {
          node {
            id
            id__normalized
            title
            content
            emp_name
            emp_office
            emp_image
            category
          }
        }
      }
      work: allIntranetPost(filter: {featured: {eq: 1}, category: {eq: "Work"}}) {
            edges {
                node {
                    id
                    id__normalized
                    title
                    link
                    type
                    image
                    excerpt
                }
            }
        }
    }
`
